import React, { ChangeEvent, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import styles from './Create.module.css';
import Directions from './Directions';
import Ingredients from './Ingredients';
import { IngredientInput, RecipeInput } from '../../generated/graphql';

enum Field {
  Title,
  Description,
  Servings,
}

interface CreateState {
  images?: FileList;
  recipe: Partial<RecipeInput>;
}

const CREATE_RECIPE = gql`
mutation Mutation($recipe: RecipeInput!) {
  createRecipe(recipe: $recipe) {
    id
  }
}
`;

function createShortcode(title: string) {
  const shortcode = title.toLowerCase().replaceAll(' ', '-');
  return encodeURIComponent(shortcode);
}

export default function Create() {
  const [state, setState] = useState({
    images: undefined,
    recipe: { createdByUserId: 1 }, // TODO Set user id based on auth
  } as CreateState);

  const [createRecipe, { data, loading, error }] = useMutation(CREATE_RECIPE);

  if (loading) return (<div>Submitting...</div>);
  if (error) return (<div>{`Submission error! ${error.message}`}</div>);
  console.log(data);

  const onImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    state.images = e.target.files || undefined;
    setState(state);
  };

  const onFieldChange = (field: Field, value: string) => {
    const { recipe } = state;
    switch (field) {
      case Field.Title:
        recipe.title = value;
        recipe.shortcode = createShortcode(value);
        break;
      case Field.Description:
        recipe.description = value;
        break;
      case Field.Servings:
        recipe.servings = parseInt(value, 10);
        break;
      default:
    }
    setState(state);
  };

  const onIngredientChange = (updatedIngredients: Array<IngredientInput>) => {
    const { recipe } = state;
    recipe.ingredients = updatedIngredients;
    setState(state);
  };

  const onDirectionsChange = (updatedDirections: Array<string>) => {
    const { recipe } = state;
    recipe.directions = updatedDirections;
    setState(state);
  };

  const onFormSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault(); // Stop form submit
    const { recipe } = state;
    createRecipe({ variables: { recipe } });
  };

  return (
    <div className={styles.create}>
      <header>
        <h1>Create a Recipe</h1>
      </header>
      <main>
        <form className={styles.recipeForm} onSubmit={onFormSubmit}>
          <label htmlFor="title">
            Title:
            <input
              name="title"
              type="text"
              onChange={(e) => onFieldChange(Field.Title, e.target.value)}
            />
            Description:
            <input
              name="description"
              type="text"
              onChange={(e) => onFieldChange(Field.Description, e.target.value)}
            />
            Servings:
            <input
              name="servings"
              type="number"
              min="1"
              max="999"
              onChange={(e) => onFieldChange(Field.Servings, e.target.value)}
            />
          </label>
          <div className="flex flex-2">
            <article>
              <header>
                <h3>Ingredients</h3>
              </header>
              <Ingredients onIngredientChange={onIngredientChange} />
            </article>
            <article>
              <header>
                <h3>Directions</h3>
              </header>
              <Directions onDirectionsChange={onDirectionsChange} />
            </article>
          </div>
          <input name="image" type="file" onChange={onImageChange} />
          <button type="submit">Submit</button>
        </form>
      </main>
    </div>
  );
}
