import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Create from './features/create/Create';
import Home from './features/home/Home';
import Recipe from './features/recipe/Recipe';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="r/:shortcode" element={<Recipe />} />
        <Route path="/create" element={<Create />} />
      </Routes>
    </Router>
  );
}

export default App;
