import { useEffect, useState } from 'react';
import { useLazyQuery, gql } from '@apollo/client';
import { useDebounce } from 'usehooks-ts';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Query, QuerySearchRecipesArgs } from '../../generated/graphql';
import styles from './Home.module.css';

const SEARCH_RECIPE = gql`
query Query($queryString: String!) {
  searchRecipes(queryString: $queryString) {
    shortcode
    title
    id
    description
  }
}
`;

export default function Home() {
  const [queryString, setQueryString] = useState('');
  const debouncedQuery = useDebounce<string>(queryString, 500);

  // eslint-disable-next-line prefer-const
  let [searchRecipes, {
    loading,
    error,
    data,
  }] = useLazyQuery<Query, QuerySearchRecipesArgs>(SEARCH_RECIPE);

  useEffect(() => {
    if (debouncedQuery.length > 2) {
      searchRecipes({ variables: { queryString: debouncedQuery } });
    }
  }, [debouncedQuery]);

  if (queryString.length <= 2) {
    loading = false;
    error = undefined;
    data = {};
  }

  if (error) {
    return (
      <p>
        {error.message}
      </p>
    );
  }

  let searchResults;
  if (loading) {
    searchResults = (
      <tr key="loading">
        <td>Searching...</td>
      </tr>
    );
  }
  if (data) {
    searchResults = data.searchRecipes?.map((recipe) => (
      <tr key={recipe?.id}>
        <td>
          <Link to={`/r/${recipe?.shortcode}`}>{recipe?.title}</Link>
        </td>
        <td>
          <Link to={`/r/${recipe?.shortcode}`} className={styles.description}>{recipe?.description}</Link>
        </td>
      </tr>
    ));
  }

  const searchResultTable = searchResults && (
    <table className={styles.searchResults}>
      <tbody>
        <tr key="header">
          <th>Title</th>
          <th>Description</th>
        </tr>
        {searchResults}
      </tbody>
    </table>
  );

  return (
    <div className={styles.recipe}>
      <header>
        <div id="banner">
          <div className="inner">
            <h1>Recipe Runt</h1>
          </div>
        </div>
      </header>
      <main>
        <div className={styles.searchBar}>
          <span className={classNames('material-symbols-outlined', styles.searchIcon)}>search</span>
          <input id="queryInput" type="text" value={queryString} onChange={(e) => setQueryString(e.target.value)} />
        </div>
        {searchResultTable}
      </main>
    </div>
  );
}
