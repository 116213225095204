import { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Recipe as RecipeModel, Ingredient as IngredientModel } from '../../generated/graphql';
import { Ingredient } from './Ingredient';
import styles from './Recipe.module.css';

type RecipeParams = {
  shortcode: string
}

interface GetRecipeVars {
  shortcode: string
}

interface RecipeData {
  recipe: RecipeModel
}

const GET_RECIPE = gql`
query Query($shortcode: String!) {
  recipe(shortcode: $shortcode) {
    title
    servings
    directions
    ingredients {
      id
      name
      quantity
      measurement {
        name
        plural
        type
        cc
        abbreviation
      }
    }
  }
}
`;

export default function Recipe() {
  const shortcode = useParams<RecipeParams>().shortcode || '';

  const { loading, error, data } = useQuery<RecipeData, GetRecipeVars>(GET_RECIPE, {
    variables: { shortcode },
  });

  const [servings, setServings] = useState(-1);
  const [multipler, setMultiplier] = useState(1);

  if (loading) return <p>Loading...</p>;
  if (error) {
    return (
      <p>
        {error.message}
      </p>
    );
  }
  if (!data) return <p>No data</p>;

  const { recipe } = data;
  if (recipe?.servings && servings === -1) {
    setServings(recipe?.servings);
  }

  const setServingsWithValidation = (servingsString: string) => {
    if (Number.isNaN(+servingsString)) return;
    const servingsInt = parseInt(servingsString, 10);
    if (servingsInt < 1 || servingsInt > 999) {
      return;
    }
    setServings(servingsInt);
    const recipeServings = recipe?.servings;
    if (recipeServings) {
      setMultiplier(servingsInt / recipeServings);
    }
  };
  const servingsText = servings > 1 ? 'servings' : 'serving';

  const ingredients = recipe?.ingredients
    .map((ingredient) => (
      <Ingredient
        key={ingredient.id}
        data={ingredient as IngredientModel}
        multiplier={multipler}
      />
    ));

  const directions: string[] = recipe?.directions;
  const directionItems = directions.map((direction) => <li>{direction}</li>);

  return (
    <div className={styles.recipe}>
      <header>
        <div id="banner">
          <div className="inner">
            <h1>{recipe?.title}</h1>
          </div>
        </div>
      </header>
      <main>
        <blockquote>
          {recipe?.description}
        </blockquote>
        <div className={styles.servings}>
          <input
            aria-label="Set servings"
            type="number"
            min="1"
            max="999"
            value={servings}
            onChange={(e) => setServingsWithValidation(e.target.value)}
          />
          {' '}
          {servingsText}
        </div>
        <div className="flex flex-2">
          <article>
            <header>
              <h3>Ingredients</h3>
            </header>
            {ingredients}
          </article>
          <article>
            <header>
              <h3>Directions</h3>
            </header>
            <ol>
              {directionItems}
            </ol>
          </article>
        </div>
      </main>
    </div>
  );
}
