import { Ingredient as IngredientModel } from '../../generated/graphql';
import getFraction from '../../utils/getFraction';
import { convertVolume, Volume } from '../../utils/measurements';

export type IngredientProps = {
  data: IngredientModel;
  multiplier: number;
};

export function Ingredient(props: IngredientProps) {
  const {
    data: {
      id, name, quantity, measurement,
    }, multiplier,
  } = props;
  let quantityString;
  if (measurement) {
    const volumeUnit = measurement.abbreviation as Volume;
    quantityString = convertVolume(quantity, volumeUnit, multiplier);
  } else {
    quantityString = getFraction(quantity * multiplier);
  }
  return (
    <label htmlFor={`ingredient-${id}`}>
      <input id={`ingredient-${id}`} type="checkbox" />
      <span>
        {quantityString}
        {' '}
        {name}
      </span>
    </label>
  );
}
