import getFraction from './getFraction';

interface MeasurementDefinition {
  singular: string;
  plural: string;
}

interface VolumeDefinition extends MeasurementDefinition {
  ml: number;
  useQuarters: boolean;
}

interface MeasurementQuantity {
  measurement: MeasurementDefinition
  quantity: number
}

export enum Volume {
  Gallon = 'gallon',
  Quart = 'quart',
  Cup = 'cup',
  Tbsp = 'tbsp',
  Tsp = 'tsp',
  Pinch = 'pinch'
}

export const volumeDefinition = {
  gallon: {
    singular: 'gallon',
    plural: 'gallons',
    ml: 3785.41,
    useQuarters: false,
  },
  quart: {
    singular: 'quart',
    plural: 'quarts',
    ml: 946.353,
    useQuarters: false,
  },
  cup: {
    singular: 'cup',
    plural: 'cups',
    ml: 236.588,
    useQuarters: true,
  },
  tbsp: {
    singular: 'tablespoon',
    plural: 'tablespoons',
    ml: 14.78676,
    useQuarters: false,
  },
  tsp: {
    singular: 'teaspoon',
    plural: 'teaspoons',
    ml: 4.92892,
    useQuarters: true,
  },
  pinch: {
    singular: 'pinch',
    plural: 'pinches',
    ml: 0.308,
    useQuarters: false,
  },
};

const {
  gallon, quart, cup, tbsp, tsp, pinch,
} = volumeDefinition;

/**
 * Volume definitions from largest to smallest
 */
const volumeArray: VolumeDefinition[] = [gallon, quart, cup, tbsp, tsp, pinch];

export function convertVolume(quantity: number, measurement: Volume, multiplier: number): string {
  const origVolume = volumeDefinition[measurement];
  const measurements: MeasurementQuantity[] = [];
  let remainingMl = quantity * origVolume.ml * multiplier;
  // eslint-disable-next-line no-restricted-syntax
  for (const volume of volumeArray) {
    const { ml, useQuarters } = volume;
    const divisor = useQuarters ? ml / 4 : ml;
    const quotient = Math.floor(remainingMl / divisor + 0.001);
    if (quotient > 0) {
      remainingMl -= quotient * divisor;
      const nextQuantity = useQuarters ? quotient / 4 : quotient;
      measurements.push({
        measurement: volume,
        quantity: nextQuantity,
      });
    }
    if (measurements.length >= 3) break;
  }

  return format(measurements);
}

function format(measurements: MeasurementQuantity[]) {
  if (measurements.length === 0) return 'smidgen';

  return measurements.map((measurementQuantity) => {
    const { quantity, measurement } = measurementQuantity;
    const fraction = getFraction(quantity);
    const measurementString = quantity > 1 ? measurement.plural : measurement.singular;
    return `${fraction} ${measurementString}`;
  }).join(' + ');
}
