const VALID_FRACTIONS = [
  { value: 1.0 / 8, code: '⅛' },
  { value: 1.0 / 6, code: '⅙' },
  { value: 1.0 / 5, code: '⅕' },
  { value: 1.0 / 4, code: '¼' },
  { value: 1.0 / 3, code: '⅓' },
  { value: 3.0 / 8, code: '⅜' },
  { value: 2.0 / 5, code: '⅖' },
  { value: 1.0 / 2, code: '½' },
  { value: 3.0 / 5, code: '⅗' },
  { value: 5.0 / 8, code: '⅝' },
  { value: 2.0 / 3, code: '⅔' },
  { value: 3.0 / 4, code: '¾' },
  { value: 4.0 / 5, code: '⅘' },
  { value: 5.0 / 6, code: '⅚' },
  { value: 7.0 / 8, code: '⅞' },
];

export default (value: number): string => {
  if (!value) {
    return '';
  }

  const floor = Math.floor(value);
  const remainder = value - floor;
  let fraction = '';
  if (remainder > 0) {
    let prevDiff = 1.0;
    // eslint-disable-next-line no-restricted-syntax
    for (const validFraction of VALID_FRACTIONS) {
      const diff = Math.abs(validFraction.value - remainder);
      if (diff > prevDiff) {
        break;
      }
      fraction = validFraction.code;
      prevDiff = diff;
    }
  }

  if (floor > 0) {
    if (fraction === '') {
      fraction = `${floor}`;
    } else {
      fraction = `${floor} ${fraction}`;
    }
  }

  return fraction;
};
