import { KeyboardEvent, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { IngredientInput, Measurement } from '../../generated/graphql';
import styles from './Create.module.css';

const GET_MEASUREMENTS = gql`
query Query {
  allMeasurements {
    id
    name
    plural
    type
    cc
    abbreviation
  }
}
`;

interface MeasurementData {
  allMeasurements: Array<Measurement>
}

export interface IngredientProps {
  onIngredientChange: (updatedIngredients: Array<IngredientInput>) => void;
}

function getValidIngredients(ingredientInputs: Partial<IngredientInput>[]): IngredientInput[] {
  let order = 1;
  return ingredientInputs
    .filter((ingredient: Partial<IngredientInput>) => {
      if (!ingredient.name || !ingredient.quantity) return false;
      return true;
    })
    .map((ingredientInput: Partial<IngredientInput>) => {
      const ingredient: IngredientInput = {
        name: ingredientInput.name || '',
        order,
        quantity: ingredientInput.quantity || 0,
        measurementId: ingredientInput.measurementId,
      };
      order += 1;
      return ingredient;
    });
}

export default function Ingredients(props: IngredientProps) {
  const [state, setState] = useState([{}] as Partial<IngredientInput>[]);
  const { loading, error, data } = useQuery<MeasurementData>(GET_MEASUREMENTS);
  const { onIngredientChange } = props;

  if (loading) return <p>Loading...</p>;
  if (error) {
    return (
      <p>
        {error.message}
      </p>
    );
  }
  if (!data) return <p>No data</p>;

  onIngredientChange(getValidIngredients(state));

  const onNameChange = (name: string, index: number) => {
    const updatedIngredients = state.slice();
    updatedIngredients[index].name = name;
    setState(updatedIngredients);
  };

  const onQuantityChange = (quantity: string, index: number) => {
    const updatedIngredients = state.slice();
    updatedIngredients[index].quantity = parseInt(quantity, 10);
    setState(updatedIngredients);
  };

  const onMeasurementChange = (measurementId: string, index: number) => {
    const updatedIngredients = state.slice();
    updatedIngredients[index].measurementId = parseInt(measurementId, 10);
    setState(updatedIngredients);
  };

  const onAddIngredientClick = (key?: string) => {
    if (key && key !== 'Enter' && key !== ' ') return;
    const updatedIngredients = state.slice();
    updatedIngredients.push({});
    setState(updatedIngredients);
  };

  const onRemoveIngredientClick = (index: number, key?: string) => {
    if (key && key !== 'Enter' && key !== ' ') return;
    const updatedIngredients = state.slice();
    updatedIngredients.splice(index, 1);
    setState(updatedIngredients);
  };

  const addButton = (
    <span
      role="button"
      className="material-symbols-outlined"
      tabIndex={0}
      onClick={() => onAddIngredientClick()}
      onKeyDown={(e: KeyboardEvent) => onAddIngredientClick(e.key)}
    >
      add_circle
    </span>
  );

  const removeButton = (index: number) => (
    <span
      role="button"
      className="material-symbols-outlined"
      tabIndex={0}
      onClick={() => onRemoveIngredientClick(index)}
      onKeyDown={(e: KeyboardEvent) => onRemoveIngredientClick(index, e.key)}
    >
      remove_circle
    </span>
  );

  const measurementList = data.allMeasurements.map((measurement) => (
    <option value={measurement.id} key={measurement.id}>{measurement.name}</option>
  ));
  measurementList.unshift(<option value={undefined} key={null}> </option>);

  const ingredientList = state.map((ingredient, index) => (
    <li>
      <div className={styles.inputRow}>
        <input
          name="quantity"
          type="number"
          min={1}
          value={ingredient.quantity}
          onChange={(e) => onQuantityChange(e.target.value, index)}
        />
        <select name="measurement" onChange={(e) => onMeasurementChange(e.target.value, index)}>
          {measurementList}
        </select>
        <input
          name="name"
          type="text"
          value={ingredient.name}
          onChange={(e) => onNameChange(e.target.value, index)}
        />
        <div className={styles.buttonGroup}>
          {removeButton(index)}
          {index === state.length - 1 ? addButton : null}
        </div>
      </div>
    </li>
  ));

  return (
    <ol>{ingredientList}</ol>
  );
}
