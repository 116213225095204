import { KeyboardEvent, useState } from 'react';
import styles from './Create.module.css';

export interface DirectionsProps {
  onDirectionsChange: (updatedDirections: Array<string>) => void;
}

export default function Directions(props: DirectionsProps) {
  const [state, setState] = useState(['']);

  const { onDirectionsChange } = props;
  onDirectionsChange(state);

  const onDirectionChange = (direction: string, index: number) => {
    const updatedDirections = state.slice();
    updatedDirections[index] = direction;
    setState(updatedDirections);
  };

  const onAddDirectionClick = (key?: string) => {
    if (key && key !== 'Enter' && key !== ' ') return;
    const updatedDirections = state.slice();
    updatedDirections.push('');
    setState(updatedDirections);
  };

  const onRemoveDirectionClick = (index: number, key?: string) => {
    if (key && key !== 'Enter' && key !== ' ') return;
    const updatedDirections = state.slice();
    updatedDirections.splice(index, 1);
    setState(updatedDirections);
  };

  const addButton = (
    <span
      role="button"
      className="material-symbols-outlined"
      tabIndex={0}
      onClick={() => onAddDirectionClick()}
      onKeyDown={(e: KeyboardEvent) => onAddDirectionClick(e.key)}
    >
      add_circle
    </span>
  );

  const removeButton = (index: number) => (
    <span
      role="button"
      className="material-symbols-outlined"
      tabIndex={0}
      onClick={() => onRemoveDirectionClick(index)}
      onKeyDown={(e: KeyboardEvent) => onRemoveDirectionClick(index, e.key)}
    >
      remove_circle
    </span>
  );

  const directionList = state.map((direction, index) => (
    <li>
      <div className={styles.inputRow}>
        <input
          name="description"
          type="text"
          value={direction}
          onChange={(e) => onDirectionChange(e.target.value, index)}
        />
        <div className={styles.buttonGroup}>
          {removeButton(index)}
          {index === state.length - 1 ? addButton : null}
        </div>
      </div>
    </li>
  ));

  return (
    <ol>{directionList}</ol>
  );
}
